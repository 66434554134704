import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Preload function
const preloadComponent = (importFn) => {
  const Component = lazy(importFn);
  Component.preload = importFn;
  return Component;
};

// Define components with preloading
const ChatPanel = preloadComponent(() => import('./components/User/Chat/ChatPage'));
const SelectedChatPanel = preloadComponent(() => import('./components/User/Chat/SelectedChat'));
const SidebarMenu = preloadComponent(() => import('./components/Admin/index'));
const LoginPage = preloadComponent(() => import('./components/Login/Login'));
const ProductPage = preloadComponent(() => import('./components/User/Product/ProductPage'));
const ProductDetails = preloadComponent(() => import('./components/User/Product/ProductDetails'));
const AddProductForm = preloadComponent(() => import('./components/User/Product/AddProductForm'));
const NotFound = preloadComponent(() => import('./components/NotFound'));
const ProductCatalog = preloadComponent(() => import('./components/User/Product/ProductCatalog'));

// Preload function to trigger component loading
const preloadRoute = (Component) => {
  if (Component.preload) {
    Component.preload();
  }
};

// LoadingSpinner component remains the same
const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

const App = () => {
  // Preload the next likely routes when the app mounts
  React.useEffect(() => {
    // Preload main routes
    preloadRoute(ChatPanel);
    preloadRoute(LoginPage);
  }, []);

  return (
    <Router>
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
          newestOnTop={false}
        />
        
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/chat" element={
              <ProtectedRoute 
                element={<ChatPanel />} 
                onEnter={() => {
                  // Preload related routes when entering chat
                  preloadRoute(SelectedChatPanel);
                  preloadRoute(ProductPage);
                }}
              />
            } />
            <Route path="/chat/:id" element={<ProtectedRoute element={<SelectedChatPanel />} />} />
            <Route path="/SidebarMenu" element={<ProtectedRoute element={<SidebarMenu />} />} />
            <Route path="/ProductPage" element={<ProtectedRoute element={<ProductPage />} />} />
            <Route path="/product/:product_code" element={<ProtectedRoute element={<ProductDetails />} />} />
            <Route path="/AddProduct" element={<ProtectedRoute element={<AddProductForm />} />} />
            <Route path="/products" element={<ProtectedRoute element={<ProductCatalog />} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
