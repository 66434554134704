// src/redux/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const initialState = {
  name: '',
  age: 0,
  userDetail: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null || '',
  collapseSideBar: false,
  collapseDrawer: false,
  selectedLanguage: localStorage.getItem('selected_language') ? localStorage.getItem('selected_language') : 'es',
  isLoggedIn: localStorage.getItem('user_session_token') || false,
  users: [], // Add a users array to store user list data
  loading: false,
  error: null,
  chatHistory: '',
  selectedChat: ''
};

// Fetch user details thunk (if needed)
export const fetchUserData = createAsyncThunk('user/fetchUserData', async (_, { rejectWithValue }) => {
  const token = localStorage.getItem('user_auth_token');
  try {
    // const response = await axios.get('https://kaiapi2.titodi.net/user/me', {
    //   headers: { Authorization: `Bearer ${token}` }
    // });

    const orgId = localStorage.getItem('orgId');
    if (orgId) {
      const orgResponse = await axios.get(`https://kaiapi2.titodi.net/org/${orgId}/members`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return orgResponse.data;
    }
  } catch (error) {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      toast.error('Token Expired!!');
      localStorage.removeItem('user_session_token');
      localStorage.removeItem('user_auth_token');
      window.location.href = '/'
      // navigate('/');
    } else {
      return rejectWithValue(error.message);
    }
  }
});

// Add user thunk
export const addUser = createAsyncThunk('user/addUser', async (newUserData, { rejectWithValue }) => {
  const token = localStorage.getItem('user_auth_token');
  try {
    const response = await axios.post(`https://kaiapi2.titodi.net/org/{orgId}/add-member`, newUserData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Delete user thunk
export const deleteUser = createAsyncThunk('user/deleteUser', async (userId, { rejectWithValue }) => {
  const token = localStorage.getItem('user_auth_token');
  try {
    await axios.delete(`https://kaiapi2.titodi.net/org/{orgId}/remove-member/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return userId;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setAge: (state, action) => {
      state.age = action.payload;
    },
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setCollapseSideBar: (state, action) => {
      state.collapseSideBar = action.payload;
    },
    setCollapseDrawer: (state, action) => {
      state.collapseDrawer = action.payload;
    },
    setChatHistory: (state, action) => {
      state.chatHistory = action.payload;
    },
    setSelectedChat: (state, action) => {
      state.selectedChat = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    removeChat: (state, action) => {
      state.chatHistory = state.chatHistory.filter(chat => chat.conversation_id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload); // Add the new user to the users array
      })
      .addCase(addUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.filter(user => user.id !== action.payload); // Remove the deleted user
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setName, setAge, setCollapseSideBar, setCollapseDrawer, setSelectedLanguage, setIsLoggedIn, setUserDetail, setChatHistory, setSelectedChat, removeChat } = userSlice.actions;

export default userSlice.reducer;
